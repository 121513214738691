import React from 'react';
import * as style from './index.module.scss';

class Partnership extends React.Component {
	render() {
		const elStyle = {
			backgroundColor: this.props.bgColor || 'transparent',
			padding: this.props.usePadding ? '20px' : 0,
		};

		return (
			<div className={style.container} style={elStyle}>
				<div className={style.wrap}>
				  {this.props.label &&
					  <h4>{this.props.label}</h4>
					}
					<div className={style.children}>
						{this.props.children}
					</div>
				</div>
			</div>
		)
	}
}

export default Partnership;