// extracted by mini-css-extract-plugin
export var label = "fellowshipHeader-module--label--NwjTI";
export var title = "fellowshipHeader-module--title--gl2mS";
export var container = "fellowshipHeader-module--container--I4wkt";
export var wrap = "fellowshipHeader-module--wrap--kjF-5";
export var authors = "fellowshipHeader-module--authors--dX610";
export var createdDate = "fellowshipHeader-module--createdDate--TezKZ";
export var feature = "fellowshipHeader-module--feature--KffKK";
export var imageWrap = "fellowshipHeader-module--imageWrap--GMK-w";
export var featuredImage = "fellowshipHeader-module--featuredImage--Qu0EQ";
export var credit = "fellowshipHeader-module--credit--pmUEv";
export var slideUp = "fellowshipHeader-module--slideUp--IAo2+";
export var slideUpLarge = "fellowshipHeader-module--slideUpLarge--MPy6l";