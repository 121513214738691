// extracted by mini-css-extract-plugin
export var spacer = "fellowship-module--spacer--gxc+8";
export var wrap = "fellowship-module--wrap--PQjcR";
export var wrapDark = "fellowship-module--wrapDark--sNDFJ";
export var container = "fellowship-module--container--zoLgj";
export var cardGrid = "fellowship-module--cardGrid--aKd8S";
export var normalTextBlock = "fellowship-module--normalTextBlock--Fi91K";
export var paragraphs = "fellowship-module--paragraphs--P84u7";
export var articleFooter = "fellowship-module--articleFooter--3A59M";
export var inlineSocialShareMobile = "fellowship-module--inlineSocialShareMobile--r9bp3";
export var shareBlock = "fellowship-module--shareBlock--nPS28";
export var shareArrowIcon = "fellowship-module--shareArrowIcon--fIq5f";
export var shareText = "fellowship-module--shareText--3F9sH";
export var threeColumn = "fellowship-module--threeColumn--FxtQa";
export var relatedReading = "fellowship-module--relatedReading--0w6rv";
export var center = "fellowship-module--center--wuAHX";
export var centerOnlyMobile = "fellowship-module--centerOnlyMobile--10xCa";
export var imageMaxWidth100 = "fellowship-module--imageMaxWidth100--QsNU7";
export var centerButtons = "fellowship-module--centerButtons--6e3kC";
export var clearBg = "fellowship-module--clearBg--8fuoK";
export var maxWidth1200 = "fellowship-module--maxWidth1200---KNzQ";
export var logo = "fellowship-module--logo--wVElT";
export var sectionHeading = "fellowship-module--sectionHeading--QnV4m";
export var fellowshipStories = "fellowship-module--fellowshipStories--3eBYt";
export var sectionInset = "fellowship-module--sectionInset--GnzTk";
export var sectionItem = "fellowship-module--sectionItem--WbAwz";
export var storyImg = "fellowship-module--storyImg--In-c6";
export var storyTitle = "fellowship-module--storyTitle--8-GRb";
export var angle = "fellowship-module--angle--lTwCb";
export var slideUp = "fellowship-module--slideUp--wXR-r";
export var slideUpLarge = "fellowship-module--slideUpLarge--LbaUw";