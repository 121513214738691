import React from 'react';
import { navigate } from 'gatsby-link';
import * as style from './fellowshipHeader.module.scss';
import translations from '../../utils/translations';


const FellowshipHeader = (props) => {
	const {
		title,
		authors,
		hideDate,
		createdDate,
		src,
		credit
	} = props;

	const _authors = authors && authors.length > 0 ? authors.map((a, index) => {
		return <React.Fragment key={index}>
			<span><strong>{a.name}</strong></span>{index < authors.length - 1 && <span>&amp;</span>}
		</React.Fragment>;
	}) : [];

	return (
		<div className={style.feature}>
			<div className={style.container}>
				<div className={style.wrap}>
					{/* */}
					<div
						onClick={() => {
							navigate('/projects')
						}}
						className={`backLink`}
					>
						<a
							className={`icon-circle-orange fa-arrow-left backIcon`}
							rel="noopener"
						>
							<span>TOP</span>
						</a>
						<a className={`backText`}>
							PROJECTS
						</a>
					</div>
					{/* */}
					<h3 className={style.label}>Fellowship Stories</h3>
					<div className={style.title}>{title}</div>
					<div className={style.authors}><strong>{translations.t('Produced by')}</strong> {_authors}</div>
					{hideDate === false
						&& <span className={style.createdDate}>
							{new Intl.DateTimeFormat(
								'en-US',
								{
									year: 'numeric',
									month: 'long',
									day: 'numeric'
								}
							).format((createdDate * 1000))}
						</span>
					}
				</div>
			</div>
			<div className={style.imageWrap}>
				<img className={style.featuredImage} src={src} />
				<div className={style.credit}>{credit}</div>
			</div>
		</div>
	)
}

export default FellowshipHeader;