import React from 'react';
import * as style from './partnership.module.scss';

const PartnershipAlternate = (props) => {
	var extraStyle = '';
	if (props.projectPage) {
		extraStyle = `${style.projectPage}`;
	}
	if (props.fellowshipPage) {
		extraStyle = `${style.fellowshipPage}`;
	}
	
	return (
		<div
			className={`${style.container}` + ' ' +extraStyle}
		>
			<div className={style.wrap}>
				<h4>
					IN PARTNERSHIP WITH
					{/* {props.label} */}
				</h4>
				<div className={style.content}>
					{props.children}
				</div>
			</div>
		</div>
	)
}

export default PartnershipAlternate;