import React, { Component } from 'react';
import Link from 'gatsby-link';
import MiniClimateMap from '../climate/miniClimateMap';
import * as style from './impactedRegions.module.scss';

const getUrl = (url, trendPath) => {
  const regionName = url.split('/')[3];
  return `${trendPath}#region-${regionName}`;
};

class ImpactedRegions extends Component {
  constructor() {
    super();
    this.state = {
      hoverId: null,
    };
  }
  handleMouseEnter = regionId => {
    this.setState({
      hoverId: regionId,
    });
  };
  handleMouseLeave = () => {
    this.setState({
      hoverId: null,
    });
  };
  render() {
    const regions =
      this.props.regions &&
      this.props.regions.map((item, i) => (
        <li
          key={`item${i}`}
          onMouseEnter={() => {
            this.handleMouseEnter(item.regionId);
          }}
          onMouseLeave={this.handleMouseLeave}
        >
          <Link to={getUrl(item.url, this.props.climateTrendPath)}>
            {item.title}
            <span className={`icon-arrow ${style.arrow}`} />
          </Link>
        </li>
      ));
    return (
      <div className={`${style.impactedRegions} text-block reveal fadeIn`}>
        <MiniClimateMap hoverId={this.state.hoverId} />
        <div className={`${style.regionListInner}`}>
          <h2>{this.props.calloutHeading}</h2>
          {regions && <ul className={style.list}>{regions}</ul>}
        </div>
      </div>
    );
  }
}

export default ImpactedRegions;
