import React from 'react';
import { navigate } from 'gatsby-link';
import RoundedButton from '../buttons/roundedButton';
import * as style from './cardGrid.module.scss';

const CardGrid = ({ cardComponent: Card, items, label, className, link }) => {
	const list = items.map(c => {
		return <Card className={style.card} data={c} />
	});

	return (
		<div className={`${className} ${style.container}`}>
			{label
				&& <div className={`${style.label} mb-40`}>{label}</div>
			}

			<div className={style.grid}>
				{list}
			</div>

			{link
				&& <div className="center-button mt-60">
					<RoundedButton type="button" text={link.title} onClick={() => navigate(link.url)} />
				</div>
			}
		</div>
	);
};

export default CardGrid;