import React from 'react';
import * as style from './bio.module.scss';

class AuthorBio extends React.Component {
	render() {
		let qualifies = 0;

		if (Array.isArray(this.props.authors) && this.props.authors.length > 0) {
			const authors = this.props.authors.map(a => {
				if (!a.description) {
					return null;
				}

				qualifies++;

				return (
					<div className={style.container}>
						<div className={style.author} key={`author_${a.id}`}>
							<div className={style.bioImage}>
								{a.bio_image.url
									&& <img src={`${process.env.GATSBY_API_HOST}${a.bio_image.url}`} alt={a.bio_image.alt} />
								}
							</div>

							<div className={style.bioText}>
								<div class="typeColorLight2" dangerouslySetInnerHTML={{
									__html: a.description
								}}></div>
								
								<div
									className={style.socialWrap}
								>
									{a.bio_url.uri && 
										<a href={a.bio_url.uri} className={style.bioUrl}>
											View Bio
										</a>
									}
									
									{/*
									{a.social_media_link.uri
										&& <a href={a.social_media_link.uri} className={style.twitterIconLink} target="_blank">
											<div className={style.twitterIcon}>&nbsp;</div>
										</a>
									}
									*/}
									{a.social_media_link.uri &&
										<a
											href={a.social_media_link.uri}
											className={`icon-social orange-orange-hover ${style.twitterLink}`}
											target="_blank"
											rel="noopener"
										>
											<div className={`icon-twitter x-white`}></div>
										</a>
									}
									
									{a.linkedin_link.uri &&
										<a
											href={a.linkedin_link.uri}
											className={`icon-social orange-orange-hover fas fa-linkedin-in ${style.linkedinLink}`}
											target="_blank"
											rel="noopener"
										>
											<span>LinkedIn</span>
										</a>
									}
								</div>
							</div>
						</div>
					</div>
				);
			});

			if (qualifies === 0) return <></>;

			return (
				<>{authors}</>
			);
		}

		return <></>;

	}
}

export default AuthorBio;