import React from 'react';
import MapRegionSVG from './mapRegionSVG';
import * as style from './miniClimateMap.module.scss';
import regionPositions from './regionPositions';

// make region Positions into array
const regionData = [];
Object.keys(regionPositions).forEach(key =>
  regionData.push({ svgData: regionPositions[key], region_id: key })
);

const MiniClimateMap = props => (
  <div className={style.miniMap}>
    <div className={style.miniMapInner}>
      <MapRegionSVG regionData={regionData} hoverId={props.hoverId} mini />
    </div>
  </div>
);

export default MiniClimateMap;
