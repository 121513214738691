import React from 'react';
import { Link } from 'gatsby';
import * as style from './projectCard.module.scss';
import translations from '../../utils/translations';

const ProjectCard = (props) => {
	let credit = props.data.credit;

	if (credit === '' && props.data.authors.length > 0) {
		credit = translations.t('By') + ' ' + props.data.authors[0].name;
	} else if (props.data.authors.length === 0 && credit === '') {
		credit = '';
	}

	return (
		<div className={`${props.className} ${style.card}`}>
			<div>
				<img src={props.data.image.sizes.square_md} alt={props.data.title} />
			</div>
			<div className={style.label}>
				{props.data.label}
			</div>
			<div className={style.title}>
				<Link to={props.data.path}>
					{props.data.title}&nbsp;
					<span className={style.angle}>&#8250;</span>
				</Link>
			</div>
			<div className={style.partnership}>
				{credit}
			</div>
		</div>
	);
};

export default ProjectCard;