// extracted by mini-css-extract-plugin
export var container = "bio-module--container--ZX7Wr";
export var author = "bio-module--author--JptBp";
export var bioImage = "bio-module--bioImage--rYUxs";
export var bioText = "bio-module--bioText--Ra9w2";
export var bioUrl = "bio-module--bioUrl--L64fJ";
export var twitterLink = "bio-module--twitterLink--7uQax";
export var linkedinLink = "bio-module--linkedinLink--p+iFy";
export var twitterIconLink = "bio-module--twitterIconLink--xu5fw";
export var twitterIcon = "bio-module--twitterIcon--Hbtxd";
export var slideUp = "bio-module--slideUp--jp4GG";
export var slideUpLarge = "bio-module--slideUpLarge--Q8Dq1";