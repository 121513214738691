import React from 'react';
import * as style from './filedUnder.module.scss';

const FiledUnder = (props) => {
	if (Array.isArray(props.categories) && props.categories.length > 0) {

		const items = props.categories.map((c, index) => {
			return <div key={index} className={style.item}>
				<a href={c.link}>{props.lang === 'es' ? c.es_name : c.name}</a>&nbsp;{index !== props.categories.length - 1 && String.fromCharCode(8226)}
			</div>;
		})

		return (
			<div className={style.container}>
				<div className={style.label}>{props.label}</div>
				{items}
			</div>
		)
	}

	return <></>;
};

export default FiledUnder;